const UrlMaker = (url) => {
    
    url = url.replace(/^\s+|\s+$/g, ''); // trim
    url = url.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeeiiiiooooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      url = url.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
  
    url = url.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
             .replace(/\s+/g, '-') // collapse whitespace and replace by -
             .replace(/-+/g, '-'); // collapse dashes

    return url
}

export default UrlMaker;