import React from 'react'

const Elado = props => {
    return (
        <main>
            <section id="forsale" class="text-center py-8">
                <div class="py-8">
                    <h1>Hamarosan!</h1>
                </div>
            </section>
        </main>
    )
}

export default Elado;